import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { AppGlobals } from '@app/app.global';
import { AuthService, SharedService, User, EntityService } from '@app/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isWithTags: any = false;
  isTaggingPending: boolean = false;
  url$: Observable<string> = new Observable<string>();
  currentURL: string;
  profileSidebarUrl: string[] = ['/profile', '/entity', '/voucher', '/user-management'];
  loggedinUser: User;
  showPushToPetpooja: boolean = false;
  isSidebarOpen = false;
  productTagsSubscription: Subscription;
  companyDetailsSubscription: Subscription;

  constructor(private sharedService: SharedService,
    public appGlobals: AppGlobals,
    public authService: AuthService,
    private entityService: EntityService,
    private router: Router,
    private cdRef: ChangeDetectorRef) {
    this.loggedinUser = this.authService.user;
    this.url$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url));
    this.url$.subscribe((resp) => { this.currentURL = resp; });
  }

  ngOnInit(): void {
    if (!this.authService.user?.isAdmin) {
      this.productTagsSubscription = this.entityService.productTags$.subscribe((res) => this.isTaggingPending = res);
      this.companyDetailsSubscription = this.entityService.companyDetails$.subscribe((res) => { this.showPushToPetpooja = res; });
    } else {
      this.showPushToPetpooja = true;
    }

    const sideBarFlag = JSON.parse(localStorage.getItem('isSidebarOpen'));
    this.isSidebarOpen = sideBarFlag;

    if (this.isSidebarOpen) {
      const sideBarElement = document.getElementById('leftSidebarMenu');

      if (sideBarElement) {
        document.body.classList.add('pmd-body-open');
        sideBarElement.classList.add('pmd-sidebar-open');
        this.cdRef.detectChanges();
      }
    }
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.sharedService.sideBarToggle.emit(this.isSidebarOpen);
    localStorage.setItem('isSidebarOpen', JSON.stringify(this.isSidebarOpen));
  }

  ngOnDestroy(): void {
    this.productTagsSubscription?.unsubscribe();
    this.companyDetailsSubscription?.unsubscribe();
  };

  // hideSideBar(){
  //   if(this.isSidebarOpen){
  //     this.sharedService.sideBarToggle.emit();
  //     this.isSidebarOpen = false;
  //   }
  // }
}