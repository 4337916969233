import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable()
export class AppGlobals {
  headers = new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*'
  });
  apiUrl = {
    authentication: environment.authentication.url + environment.authentication.path,
    dashboard: environment.dashboard.url + environment.dashboard.path,
    invoice: environment.invoice.url + environment.invoice.path,
    metadata: environment.metadata.url + environment.metadata.path
  };
  bypassApiUrl = {
    invoice: environment.invoice.bypassUrl + environment.invoice.bypassPath
  };
  minPasswordLength = 8;
  otpLength = 6;
  regex = {
    email: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    mobile: new RegExp(/^\d{10}$/),
    number: new RegExp(/^(?=.*?\d)/),
    numberOnly: new RegExp(/^\d+(\.\d+)?$/),
    lowerCase: new RegExp(/^(?=[^a-z]*[a-z])/),
    upperCase: new RegExp(/^(?=[^A-Z]*[A-Z])/),
    pan: new RegExp(/^[A-Z]{3}[PCHABGJLFT][A-Z]\d{4}[A-Z]$/),
    gst: new RegExp(/^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1})$/),
    symbol: new RegExp(/^(?=.*?[#?!@$%^&*-])/),
    password: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).+$/),
    length: (min?: number, max?: number) => new RegExp('^.{' + (min ?? '') + ',' + (max ?? '') + '}$'),
    name: new RegExp(/^[a-z\s]+$/i),
    onlyCharacter: new RegExp(/[a-zA-Z\s]+/),
    passwordEmail: new RegExp(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}|\d{10})$/),
    fieldName: new RegExp(/^[a-z0-9\s!@#$%^&*()\-_+={}[\]:;"'<,>.?\/~`₹\|]+$/i),
    fieldNameSpecialCharacters: '!@#$%^&*()-_+={}[]:;"\'<,>.?/~`₹\|',
    date: new RegExp(/^\d{4}-\d{2}-\d{2}$/),
    companyName: new RegExp(/^(?!\s*$)[\w&@*.'()\-\s]+$/),
    Voucher: new RegExp(/^[a-zA-Z0-9]{3}$/),
    fullname: new RegExp(/^(?!\s*$)[a-zA-Z0-9\s]+$/i),
    cgstRate: new RegExp(/\bcgst\s*\d+(\.\d+)?\s*%/i),
    sgstRate: new RegExp(/\bsgst\s*\d+(\.\d+)?\s*%/i),
    igstRate: new RegExp(/\bigst\s*\d+(\.\d+)?\s*%/i),
    tadRate: new RegExp(/\btad\s*\d+(\.\d+)?\s*%/i),
    cgst: new RegExp(/cgst/i),
    sgst: new RegExp(/sgst/i),
    igst: new RegExp(/igst/i),
    tad: new RegExp(/tad/i),
    excise: new RegExp(/excise/i),
    vat: new RegExp(/VAT/i)
  };
  defaultDebounceTime = 400;
  allowedMaxInvoiceCount = 10;
  allowedMaxInvoiceSize = 150 * 1024 * 1024;
  allowedMaxInvoicePageCount = 10;
  allowedMaxInvoicePageSize = 150 * 1024 * 1024;
  invoicePerPage = 10;
  pageSizes = [10, 20, 30];
  invoiceListUpdateInterval = 10 * 1000;
  inprocessInvoiceUpdateInterval = 30 * 1000;
  homePath = ['/dashboard'];
  leadAdminPath = ['/invoice-review-summary'];
  adminPath = ['/invoice'];
  retryCount = 5;
  retryWaitMilliSeconds = 500;
  dateFormat: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  minInvoiceForDashboard = 0;
  timer = 30;
  minAsyncSearchCharacter = 3;
  maxProductSearchLimit = 10;
  minimumInvoiceDate = new Date('2020-01-01');
  invoiceDateFormat = 'YYYY-MM-DD';
  idleReviewTimeMS = 5 * 1000;
  PTTableFields = {
    quantity: 'quantity',
    rate: 'rate',
    nettotal: 'nettotal',
    subtotal: 'subTotal',
    discountamount: 'discountAmount',
    discountRate: 'discountRate',
    discount: 'discount',
    gst: 'gst',
    gstAmount: 'gstAmount',
    cgstAmount: 'cgstAmount',
    sgstAmount: 'sgstAmount',
    igstAmount: 'igstAmount',
    utgstAmount: 'utgstAmount',
    grandtotal: 'grandTotal',
    totalamount: 'totalAmount',
    gstRate: 'gstRate',
    cgstRate: 'cgstRate',
    sgstRate: 'sgstRate',
    igstRate: 'igstRate',
    utgstRate: 'utgstRate',
    taxableAmount: 'taxableAmount',
    nonTaxableAmount: 'nonTaxableAmount',
    cgst: 'cgst',
    sgst: 'sgst',
    igst: 'igst',
    roundOff: 'roundOff',
    cessamount: 'cessAmount',
    cgst2Point5Percent: 'cgst2point5percent',
    sgst2Point5Percent: 'sgst2point5percent',
    tad2Point5Percent: 'tad2point5percent',
    cgst1Point5Percent: 'cgst1point5percent',
    sgst1Point5Percent: 'sgst1point5percent',
    tad1Point5Percent: 'tad1point5percent',
    cgst6Percent: 'cgst6percent',
    sgst6Percent: 'sgst6percent',
    tad6Percent: 'tad6percent',
    cgst9Percent: 'cgst9percent',
    sgst9Percent: 'sgst9percent',
    tad9Percent: 'tad9percent',
    cgst14Percent: 'cgst14percent',
    sgst14Percent: 'sgst14percent',
    tad14Percent: 'tad14percent',
    igst3Percent: 'igst3percent',
    tad3Percent: 'tad3percent',
    igst5Percent: 'igst5percent',
    tad5Percent: 'tad5percent',
    igst12Percent: 'igst12percent',
    tad12Percent: 'tad12percent',
    igst18Percent: 'igst18percent',
    tad18Percent: 'tad18percent',
    igst28Percent: 'igst28percent',
    tad28Percent: 'tad28percent',
    cessRate: 'cessRate',
    rateExclusiveTax: 'rateExclusive',
    vatRate: 'vatRate',
    vatAmount: 'vatAmount',
    exciseRate: 'exciseRate',
    vat: 'vat',
    excise: 'excise',
    cess: 'cess',
    unit: 'unit',
    liquorAmount: 'liquorAmount',
    tad: 'tad',
    otherCharges: 'otherCharges',
  }
  TTTableStrings: any = {
    subtotal: 'Sub Total',
    grandtotal: 'Grand Total',
    roundoff: 'Round Off',
    nettotal: 'Net Total',
    discountamount: 'Discount Amount',
    discountrate: 'Discount Rate',
    gstamount: 'GST Amount',
    cgstamount: 'CGST Amount',
    sgstamount: 'SGST Amount',
    igstamount: 'IGST Amount',
    utgstamount: 'UTGST Amount',
    totalamount: 'Total Amount',
    quantity: 'Quantity',
    rate: 'Rate',
    taxableamount: 'Taxable Amount',
    nontaxableamount: 'Non-Taxable Amount',
    cgst: 'cgst',
    sgst: 'sgst',
    igst: 'igst',
    cessamount: 'CESS Amount',
    gstrate: 'GST Rate',
    cgstrate: 'CGST Rate',
    sgstrate: 'SGST Rate',
    igstrate: 'IGST Rate',
    utgstrate: 'UTGST Rate',
    utgst: 'utgst',
    cgst1point5Percent: 'CGST 1.5%',
    sgst1point5Percent: 'SGST 1.5%',
    tad1point5Percent: 'TAD 1.5%',
    cgst2point5Percent: 'CGST 2.5%',
    sgst2point5Percent: 'SGST 2.5%',
    tad2point5Percent: 'TAD 2.5%',
    cgst6Percent: 'CGST 6%',
    sgst6Percent: 'SGST 6%',
    tad6Percent: 'TAD 6%',
    cgst9Percent: 'CGST 9%',
    sgst9Percent: 'SGST 9%',
    tad9Percent: 'TAD 9%',
    cgst14Percent: 'CGST 14%',
    sgst14Percent: 'SGST 14%',
    tad14Percent: 'TAD 14%',
    igst3Percent: 'IGST 3%',
    tad3Percent: 'TAD 3%',
    igst5Percent: 'IGST 5%',
    tad5Percent: 'TAD 5%',
    igst12Percent: 'IGST 12%',
    tad12Percent: 'TAD 12%',
    igst18Percent: 'IGST 18%',
    tad18Percent: 'TAD 18%',
    igst28Percent: 'IGST 28%',
    tad28Percent: 'TAD 28%',
    cessrate: 'CESS Rate',
    rateExclusiveTax: 'Rate Exclusive',
    vatrate: 'VAT Rate',
    liquorAmount: 'Liquor Amount',
    exciserate: 'Excise Rate',
    vat: 'VAT',
    excise: 'Excise',
    exciseAmount: 'Excise Amount',
    discount: 'discount',
    cess: 'Cess',
    description: 'description',
    vatamount: 'VAT Amount',
    hsn: 'HSN Code/SAC',
    tad: 'tad'
  }
  JSSFormula = {
    Product: 'PRODUCT',
    Division: '/',
    SUM: 'SUM'
  }
  JSSOtherCharges = [
    'Delivery Charges',
    'Shipping Charges',
    'Freight And Packing Charges',
    'Transportation Charges',
    'Insurance Charges',
    'Packing And Forwarding Charges',
    'Fuel Charges',
    'Packing Charges',
    'Labour Charges',
    'Courier Charges',
    'Freight Charges',
    'Other Charges'
  ];
  tax = ['gstAmount', 'cgstAmount', 'sgstAmount', 'igstAmount', 'cessAmount', 'vatAmount', 'exciseAmount'];
  jspTaxes = [{
    taxes: [{
      taxes: [{
        name: 'igstAmount',
        isPercentage: false
      }],
      alternativeTaxes: [{
        name: 'igstRate',
        isPercentage: true
      }]
    }],
    alternativeTaxes: [{
      taxes: [{
        taxes: [{
          taxes: [{
            name: 'cgstAmount',
            isPercentage: false
          }],
          alternativeTaxes: [{
            name: 'cgstRate',
            isPercentage: true
          }]
        }]
      }, {
        taxes: [{
          taxes: [{
            name: 'sgstAmount',
            isPercentage: false
          }],
          alternativeTaxes: [{
            name: 'sgstRate',
            isPercentage: true
          }]
        }]
      }],
      isAllMandatory: true,
      alternativeTaxes: [{
        taxes: [{
          name: 'gstAmount',
          isPercentage: false
        }],
        alternativeTaxes: [{
          name: 'gstRate',
          isPercentage: true
        }]
      }]
    }]
  }, {
    taxes: [{
      name: 'cessAmount',
      isPercentage: false
    }],
    alternativeTaxes: [{
      name: 'cessRate',
      isPercentage: true
    }]
  }, {
    taxes: [{
      name: 'exciseAmount',
      isPercentage: false
    }],
    alternativeTaxes: [{
      name: 'exciseRate',
      isPercentage: true
    }]
  }, {
    taxes: [{
      name: 'vatAmount',
      isPercentage: false
    }],
    alternativeTaxes: [{
      name: 'vatRate',
      isPercentage: true
    }]
  }];
  taxFields = ['GST Amount', 'CGST Amount', 'SGST Amount', 'IGST Amount', 'UTGST Amount', 'GST Rate', 'CGST Rate', 'SGST Rate', 'IGST Rate', 'UTGST Rate'];
  taxRates = ['GST Rate', 'CGST Rate', 'SGST Rate', 'IGST Rate', 'UTGST Rate'];
  rateOtherCharges = [
    'courierCharges',
    'deliveryCharges',
    'freightAndPackingCharges',
    'freightCharges',
    'fuelCharges',
    'insuranceCharges',
    'labourCharges',
    'otherCharges',
    'packingAndForwardingCharges',
    'packingCharges',
    'shippingCharges',
    'transportationCharges',
    'discount'
  ];
  tcsOtherCharges = [
    'courierCharges',
    'deliveryCharges',
    'discount',
    'freightAndPackingCharges',
    'freightCharges',
    'fuelCharges',
    'insuranceCharges',
    'labourCharges',
    'otherCharges',
    'packingAndForwardingCharges',
    'packingCharges',
    'shippingCharges',
    'transportationCharges'
  ];
  PURCHASE = 'purchase';
  EXPENSE = 'expense';
  JSSNetTotalField = { field: this.PTTableFields.nettotal, name: this.PTTableFields.nettotal };
  // jspreadsheet key for local env
  // JSpreadSheetLicense = 'ZjNhY2MzOTZkMDJmYzgzZmExZTdlMTEwY2RmZGQ1N2ZkZjVhZjc2ZDgzOGJkYzQyMmIxNWQ2NmNmNWVkZmIwYWI3YTc0NjRmMWM1NTg5NzZlM2QxOGNlZDNmYjUzNDMyNTAwNTQzNThiZjIxYWU2YzdkMjM0YmMzNTVmYzFhMWQsZXlKdVlXMWxJam9pVm1seVpXNWtjbUVpTENKa1lYUmxJam94TnpFek1UTTFOakF3TENKa2IyMWhhVzRpT2xzaWQyVmlJaXdpYkc5allXeG9iM04wSWwwc0luQnNZVzRpT2pBc0luTmpiM0JsSWpwYkluWTNJaXdpZGpnaUxDSjJPU0lzSW5ZeE1DSmRmUT09'
  // jspreadsheet key for all domains
  JSpreadSheetLicense = 'YTlkMGI5YmU4YmU5NTNmMjlkNTZlNTQzZWE1MWEzOTk1MDQwY2Y1Yjk1N2RiZDgyM2Y5MWNlOTgzMGQwZDYyYTRiZDlhODQ4YjczNWU2NTJkZDcwNDFlNzU1ZjI5MTk3NzQwNmE0OWU0ZDA3MjQ1Y2M0OWY1ODUyMDlhMTJhYTYsZXlKamJHbGxiblJKWkNJNklqRm1abVEyWWpGbE5tUTFOV000TkRBNVpUQmpNelJrTlRCbU16bGpNMlU0WVRKaU5UVXpaV1lpTENKdVlXMWxJam9pVG1GcGRHbHJJRk5vWVdnaUxDSmtZWFJsSWpveE56WTJORFE0TURBd0xDSmtiMjFoYVc0aU9sc2lZWFJvWlc1aGFXNTJiMmxqWlM1d1pYUndiMjlxWVM1amIyMGlMQ0pwYm5admFXTmxMbkJsZEhCdmIycGhMbU52YlNJc0ltUmxiV1YwWlhKcGJuWnZhV05sTG5CbGRIQnZiMnBoTG1OdmJTSXNJbnBsZFhOcGJuWnZhV05sTG5CbGRIQnZiMnBoTG1OdmJTSXNJbmRsWWlJc0lteHZZMkZzYUc5emRDSmRMQ0p3YkdGdUlqb2lNek1pTENKelkyOXdaU0k2V3lKMk55SXNJblk0SWl3aWRqa2lMQ0oyTVRBaUxDSjJNVEVpTENKbWIzSnRjeUlzSW5KbGJtUmxjaUlzSW5CaGNuTmxjaUlzSW1sdGNHOXlkR1Z5SWl3aWRtRnNhV1JoZEdsdmJuTWlMQ0pqYjIxdFpXNTBjeUlzSW5ObFlYSmphQ0lzSW1Ob1lYSjBjeUlzSW1admNtMTFiR0VpWFgwPQ=='
  otherChargesRates = [0, 3, 5, 12, 18, 28];
  otherChargesHalfRates = [0, 1.5, 2.5, 6, 9, 14];
  GSTRelatedColumns = ['GST Rate', 'CGST Rate', 'GST Amount', 'SGST Rate', 'CGST Amount', 'SGST Amount'];
  IGSTRelatedColumns = ['IGST Rate', 'IGST Amount'];
  GSTRates = {
    3: {
      fieldIGST: this.PTTableFields.igst3Percent,
      nameIGST: this.TTTableStrings.igst3Percent,
      fieldCGST: this.PTTableFields.cgst1Point5Percent,
      nameCGST: this.TTTableStrings.cgst1point5Percent,
      fieldSGST: this.PTTableFields.sgst1Point5Percent,
      nameSGST: this.TTTableStrings.sgst1point5Percent,
      fieldTAD: this.PTTableFields.tad1Point5Percent,
      nameTAD: this.TTTableStrings.tad1point5Percent,
      fieldIGSTTAD: this.PTTableFields.tad3Percent,
      nameIGSTTAD: this.TTTableStrings.tad3Percent
    },
    5: {
      fieldIGST: this.PTTableFields.igst5Percent,
      nameIGST: this.TTTableStrings.igst5Percent,
      fieldCGST: this.PTTableFields.cgst2Point5Percent,
      nameCGST: this.TTTableStrings.cgst2point5Percent,
      fieldSGST: this.PTTableFields.sgst2Point5Percent,
      nameSGST: this.TTTableStrings.sgst2point5Percent,
      fieldTAD: this.PTTableFields.tad2Point5Percent,
      nameTAD: this.TTTableStrings.tad2point5Percent,
      fieldIGSTTAD: this.PTTableFields.tad5Percent,
      nameIGSTTAD: this.TTTableStrings.tad5Percent
    },
    12: {
      fieldIGST: this.PTTableFields.igst12Percent,
      nameIGST: this.TTTableStrings.igst12Percent,
      fieldCGST: this.PTTableFields.cgst6Percent,
      nameCGST: this.TTTableStrings.cgst6Percent,
      fieldSGST: this.PTTableFields.sgst6Percent,
      nameSGST: this.TTTableStrings.sgst6Percent,
      fieldTAD: this.PTTableFields.tad6Percent,
      nameTAD: this.TTTableStrings.tad6Percent,
      fieldIGSTTAD: this.PTTableFields.tad12Percent,
      nameIGSTTAD: this.TTTableStrings.tad12Percent
    },
    18: {
      fieldIGST: this.PTTableFields.igst18Percent,
      nameIGST: this.TTTableStrings.igst18Percent,
      fieldCGST: this.PTTableFields.cgst9Percent,
      nameCGST: this.TTTableStrings.cgst9Percent,
      fieldSGST: this.PTTableFields.sgst9Percent,
      nameSGST: this.TTTableStrings.sgst9Percent,
      fieldTAD: this.PTTableFields.tad9Percent,
      nameTAD: this.TTTableStrings.tad9Percent,
      fieldIGSTTAD: this.PTTableFields.tad18Percent,
      nameIGSTTAD: this.TTTableStrings.tad18Percent
    },
    28: {
      fieldIGST: this.PTTableFields.igst28Percent,
      nameIGST: this.TTTableStrings.igst28Percent,
      fieldCGST: this.PTTableFields.cgst14Percent,
      nameCGST: this.TTTableStrings.cgst14Percent,
      fieldSGST: this.PTTableFields.sgst14Percent,
      nameSGST: this.TTTableStrings.sgst14Percent,
      fieldTAD: this.PTTableFields.tad14Percent,
      nameTAD: this.TTTableStrings.tad14Percent,
      fieldIGSTTAD: this.PTTableFields.tad28Percent,
      nameIGSTTAD: this.TTTableStrings.tad28Percent
    }
  }
  ReviewPageHideHTMLTabDate = new Date('September 25, 2024 23:59:59');
  GOOGLE_RECAPTCHA_SITEKEY = '6LcKp1AqAAAAAAs-QMiwvjcj9y3STpmgF2DSUUdO';
  userAccessList = [
    {
      name: "Dashboard",
      url: '/dashboard',
      regex: new RegExp('^/dashboard$')
    },
    {
      name: "Upload Invoice",
      url: '/invoice/upload',
      regex: new RegExp('^/invoice/upload$')
    },
    {
      name: "Invoice Listing",
      url: '/invoice',
      regex: new RegExp('^/invoice$')
    },
    {
      name: "Invoice Review",
      url: '/invoice/{id}',
      regex: new RegExp('^/invoice/[a-f0-9]{24}$')
    },
    {
      name: "Tag Management",
      url: '/tag',
      regex: new RegExp('^/tag$')
    },
    {
      name: "Payments",
      url: '/payments',
      regex: new RegExp('^/payments$')
    },
    {
      name: "Reports",
      url: '/report',
      regex: new RegExp('^/report$')
    },
    {
      name: "Petty Expense",
      url: '/petty-expense',
      regex: new RegExp('^/petty-expense$')
    },
    {
      name: "Entity Management",
      url: '/entity',
      regex: new RegExp('^/entity$')
    },
    {
      name: "Push to Petpooja",
      url: '/push-to-petpooja',
      regex: new RegExp('^/push-to-petpooja$')
    },
    {
      name: "Voucher",
      url: '/voucher',
      regex: new RegExp('^/voucher$')
    },
    {
      name: "Banking",
      url: '/banking',
      regex: new RegExp('^/banking$')
    }
  ];

  USER_ACCESS = {
    DASHBAORD: 'Dashboard',
    UPLOAD_INVOICE: 'Upload Invoice',
    INVOICE_LISTING: 'Invoice Listing',
    INVOICE_REVIEW: 'Invoice Review',
    TAG_MANAGEMENT: 'Tag Management',
    PAYMENTS: 'Payments',
    REPORTS: 'Reports',
    PATTY_EXPENSE: 'Petty Expense',
    ENTITY_MANAGEMENT: 'Entity Management',
    PUSH_TO_PETPOOJA: 'Push to Petpooja',
    VOUCHER: 'Voucher',
    BANKING: 'Banking',
    UNKNOWN: 'Unknown',
  };
  maxDays = 90
}